import React from 'react';
import referralImg from '../../../assets/newReferral.png'; // Adjusted path
import profileImg from '../../../assets/patientProfile.png'; // Adjusted path
import searchImg from '../../../assets/patientSearch.png'; // Adjusted path
import styles from '../Solutions.module.css'; // Import the CSS file


function Solutions() {
    return (

        <>
            <section id="solutions" className={styles.section}>
                <h2 className={styles.heading}>Here's Why . . .</h2>
                <div className={styles.videoContainer}>
                    <iframe className={styles.iframeVideo} src="https://player.vimeo.com/video/1049883350?h=2f396b2e7f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" title="My Patient Status Overview"></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </section>
        </>
    );
}

export default Solutions;


// SAVED IN CASE OF FUTURE USE
//<div className={styles.images}>
//                    <img src={referralImg} alt="Solution 1" className={`${styles.image} ${styles.image1}`} />
//                   <img src={profileImg} alt="Solution 2" className={`${styles.image} ${styles.image2}`} />
//                    <img src={searchImg} alt="Solution 3" className={`${styles.image} ${styles.image3}`} />
//                </div>
//                <div className={styles.listContainer}>
//                    <ul className={styles.list}>
//                        <li>Search for patient profiles, allowing for easy access to any patient information.</li>
//                        <li>Add new Referrals from an unlimited number of referring offices</li>
//                        <li>View files and Messages from the referring office.</li>
//                    </ul>
//                    <div className={styles.promoLinkContainer}>
//                        <a href="https://vimeo.com/1009319714/0ba1ab3c2b" className={styles.clickHereButton}>Click Here to Watch Our Promo Video</a>
//                    </div>
//                </div>
//padding:56.25% 0 0 0;position:relative; 1st
//style="position:absolute;top:0;left:0;width:100%;height:100%;" 2nd
//start pciture: 2f396b2e7f
//<div className={styles.videoContainer}>
//                    <iframe className={styles.iframeVideo} src="https://player.vimeo.com/video/1009319714?h=0ba1ab3c2b&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1080" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="My Patient Status Overview"></iframe>
//
//                </div>